import React from "react";

const ProfPic = "https://i.ibb.co/6BYVqsx/laurapinky.jpg";

function AboutMePage() {
  return (
    <div className="AboutMe">
      <div className="AboutImg">
        <img src={ProfPic} alt="Laura and dog" />
      </div>
      <div className="AboutText">
        <h3>Hello! I'm Laura, and I build web apps and tools.</h3>
        <p>
          I mostly do front-end design and development, and I also like
          playing with databases, so I've learned PHP, MySQL, and even a little
          Python and Bash. I love bridging the gap between design and technical solutions.
          When I'm not coding, I'm probably out taking foster dogs on hikes, sewing,
          or reading.
        </p>
        <a
          href="https://www.linkedin.com/in/laura-brown-898b8a52/"
          target="_blank"
          rel="noreferrer"
        >
          <button>Let's connect on LinkedIn!</button>
        </a>
      </div>
    </div>
  );
}

export default AboutMePage;
