import React from "react";

import ProjPreview from "./ProjPreview";

import Languages from "./Languages";

import mSEProject from "../projects/mSE-Project";
import ScrollingInfoProject from "../projects/scrollingInfo-Project";
import InfoExpProject from "../projects/infoExp-Project";
import MotionReelProject from "../projects/motionReel-Project";
import vs3Project from "../projects/vs3-Project";
import portfolioProject from "../projects/portfolio-Project";
import emailTemplatesProject from "../projects/emailTemplates-Project";
import TheCenterWordpress from "../projects/theCenterWordpress-Project";
import rgbdleProject from "../projects/rgbdle-Project";
import vibesEmailProject from "../projects/vibesEmail-Project";
import decisionMakingToolProject from "../projects/decisionMakingTool-Project";
import surveyRedesignProject from "../projects/surveyRedesign-Project";

class ProjectPreviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        InfoExpProject,
        surveyRedesignProject,
        decisionMakingToolProject,
        vs3Project,
        emailTemplatesProject,
        rgbdleProject,
        ScrollingInfoProject,
        vibesEmailProject,
        TheCenterWordpress,
        mSEProject,
        portfolioProject,
        MotionReelProject,
      ],
    };
  }

  makeProjects = (projects) => {
    let langFilt = this.props.filter;
    let actualLang = "";
    if (langFilt) {
      actualLang = Languages.find((lang) => lang.shortName === langFilt).name;
      let projs = this.state.projects;
      let numProjs = projs.filter((proj) =>
        proj.skills.includes(actualLang)
      ).length;
      let flexGrow = 0;
      if (numProjs === 1) {
        flexGrow = 1;
      }

      if (numProjs > 0) {
        return projs
          .filter((proj) => proj.skills.includes(actualLang))
          .map((project, index) => (
            <ProjPreview
              key={index}
              delay={index}
              project={project}
              grow={flexGrow}
            />
          ));
      } else {
        let noText = Languages.find(
          (lang) => lang.shortName === langFilt
        ).noneText;
        return <div>{noText}</div>;
      }
    } else {
      return this.state.projects.map((project, index) => (
        <ProjPreview
          key={index}
          delay={index}
          project={project}
        />
      ));
    }
  };

  render() {
    return (
      <div className="ProjectPreviews">
        {this.makeProjects(this.state.projects)}
      </div>
    );
  }
}

export default ProjectPreviews;
