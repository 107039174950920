import React from "react";

import { extLink } from "../img/otherImgs.js";

function Project(props) {
  const langCats = {
    FE: ["HTML5", "CSS3", "JS/ES6", "React", "MJML", "ExtendScript", "SCSS"],
    PG: ["AfterEffects", "Illustrator", "AdobeXD", "Figma"],
    BE: ["PHP", "MySQL"],
  };

  return (
    <div className="Project" id={props.project.shortTitle}>
      {props.project.heroImage && (
        <img
          className={props.project.optstyles}
          src={props.project.heroImage}
          alt={props.project.heroAlt}
        />
      )}

      {props.project.heroVideo && (
        <div className="iframe-container">
          <iframe
            src={props.project.heroVideo}
            title={props.project.title}
            width="1280"
            height="720"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}

      <div className="Proj">
        <div className="ProjIntro">
          <div className="IntroText">
            <h3>{props.project.title}</h3>
            {props.project.introText.map((introText) => (
              <p key={introText.index} index={introText.index}>
                {introText.text}
              </p>
            ))}
          </div>
          <div className="BuiltWith">
            {props.project.extLink && (
              <p>
                <a href={props.project.extLink}>
                  <button>
                    {props.project.extLinkText}
                    <img
                      src={extLink}
                      style={{ height: 15, width: 15, paddingLeft: 5 }}
                      alt="External Link Icon by shashank singh from NounProject.com"
                    ></img>
                  </button>
                </a>
              </p>
            )}
            <p>
              {props.project.skills.find((skill) =>
                langCats.FE.includes(skill)
              ) && <span className="BuiltCat">Front-end</span>}

              {props.project.skills
                .filter((skill) => langCats.FE.includes(skill))
                .map((skill) => {
                  return <span>{skill}</span>;
                })}
            </p>
            <p>
              {props.project.skills.find((skill) =>
                langCats.BE.includes(skill)
              ) && <span className="BuiltCat">Back-end</span>}
              {props.project.skills
                .filter((skill) => langCats.BE.includes(skill))
                .map((skill) => {
                  return <span>{skill}</span>;
                })}
            </p>
            <p>
              {props.project.skills.find((skill) =>
                langCats.PG.includes(skill)
              ) && <span className="BuiltCat">Design Programs</span>}
              {props.project.skills
                .filter((skill) => langCats.PG.includes(skill))
                .map((skill) => {
                  return <span>{skill}</span>;
                })}
            </p>
          </div>
        </div>

        <div className="ProjDesc">
          {props.project.imgsParas.map((para) => (
            <div key={para.index} className="DescSec">
              <img
                src={para.img}
                alt={para.alt}
                className={props.project.optstyles}
              />
              <p>{para.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Project;
