import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import mSEProject from "../projects/mSE-Project";
import ScrollingInfoProject from "../projects/scrollingInfo-Project";
import InfoExpProject from "../projects/infoExp-Project";
import MotionReelProject from "../projects/motionReel-Project";
import vs3Project from "../projects/vs3-Project";
import emailTemplatesProject from "../projects/emailTemplates-Project";
import TheCenterWordpress from "./theCenterWordpress-Project";
import rgbdleProject from "./rgbdle-Project";
import vibesEmailProject from "./vibesEmail-Project";
import portfolioProject from "../projects/portfolio-Project";
import decisionMakingToolProject from "./decisionMakingTool-Project";
import surveyRedesignProject from "./surveyRedesign-Project";

import Project from "../components/Project";

export default function AllProjects(props) {
  let { id } = useParams();

  const projects = [
    InfoExpProject,
    rgbdleProject,
    emailTemplatesProject,
    vs3Project,
    ScrollingInfoProject,
    vibesEmailProject,
    mSEProject,
    MotionReelProject,
    TheCenterWordpress,
    portfolioProject,
    decisionMakingToolProject,
    surveyRedesignProject,
  ];

  //using useEffect here to avoid an error
  useEffect(() => {
    projects.map((project) => {
      if (project.shortTitle === id) {
        props.passSkillsData(project.skills);
      }
      return "";
    });
  });

  return (
    <div>
      <div>
        {projects.map((project, index) => {
          if (project.shortTitle === id) {
            return (
              <div key={index}>
                <Project
                  key={project.shortTitle}
                  project={project}
                />
              </div>
            );
          }

          return <div key={index}></div>;
        })}
      </div>
    </div>
  );
}
